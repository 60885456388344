<template>
  <v-main class="container-prosker">
    <v-img
          aspect-ratio="2.4"
          :src="$i18n.locale.toLowerCase().includes('br')
            ? require('@/assets/images/page-img/subscription_br.png')
            : require('../../assets/images/page-img/businessGrow.png')"
          cover
          class="text-white"
        >
      <div :class="!$vuetify.breakpoint.mobile ? 'button-absolute' : 'button-absolute-mobile' ">
        <subscription-button :subscribe="beginOnProsk" :message="$t('freeTrial30')" class="h-md-25 p-md-0"/>
      </div>
    </v-img>
    <v-row v-show="!$vuetify.breakpoint.mobile">
      <v-col cols="12" class="text-center mb-4">
        <h2 class="font-weight-bold secondary-prosk">
          {{ $t("subscriptionText1") }}
        </h2>
      </v-col>
    </v-row>
    <v-card
      min-height="480"
      class="info-card"
    >
      <div class="line-separator secondary-prosk">
        <v-row >
          <v-col cols="12" md="4" >
            <div class="d-flex flex-column text-center align-center">
              <div class="bg-white custom-radio">
                <img
                  :src="require('../../assets/images/page-img/form.svg')"
                  alt="subscription"
                />
              </div>
              <h4 class="font-weight-bold secondary-prosk mb-6">{{ $t("boostBusiness") }}</h4>
              <p>{{ $t("increaseVisibility") }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="4" >
            <div class="d-flex flex-column text-center align-center">
              <div class="bg-white custom-radio">
                <img
                  :src="require('../../assets/images/page-img/tools.svg')"
                  alt="subscription"
                />
              </div>
              <h4 class="font-weight-bold secondary-prosk mb-6">{{ $t("optimize") }}</h4>
              <p>{{ $t("forgetPendencies") }}</p>
            </div>
          </v-col>
          <v-col  cols="12" md="4" :class="$vuetify.breakpoint.mobile? 'mb-8' : ''">
            <div class="d-flex flex-column text-center align-center">
              <div class="bg-white custom-radio">
                <img
                  :src="require('../../assets/images/page-img/potence.svg')"
                  alt="subscription"
                />
              </div>
              <h4 class="font-weight-bold secondary-prosk mb-6">{{ $t("enjoyTheTools") }}</h4>
              <p>{{ $t("automateProcess") }}</p>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="d-flex justify-content-center">
        <subscription-button :subscribe="beginOnProsk" :message="$t('freeTrial30')" />
      </div>
    </v-card>
    <v-row>
      <v-col cols="12" class="text-center my-10">
        <h1 class="font-weight-bold secondary-prosk">
          {{ $t("nextLevel") }}
        </h1>
        <v-container class="text-center px-16">
          <h4
            class="font-weight-bold secondary-prosk"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-0' : 'mx-16 px-16'"
          >{{ $t("subscribeAndGrowYourBusiness") }}</h4>
        </v-container>
      </v-col>
      <v-container>
          <div :class="$vuetify.breakpoint.mobile ? 'mx-8' : 'mx-16'">
              <v-row v-if="loading" class="mx-16">
                  <v-col v-for="index in 2" :key="index" cols="6">
                      <v-skeleton-loader
                          v-bind="skeletonAttrs"
                          type="article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line,
                           list-item-two-line, actions"
                      ></v-skeleton-loader>
                  </v-col>
              </v-row>
              <v-row v-else>
                  <v-col
                  class="pa-0"
                  cols="12"
                  md="6"
                  v-for="(item, i) in this.subscriptionPlans"
                  :key="i"
              >
                  <div
                      :class="item.popular
                      ? 'bg-subscription-card bg-green-prosk pt-3 pb-5 px-5'
                      : 'pt-3 pb-5 px-5'">
                      <h3
                          class="pb-3 text-center font-weight-bold"
                          :class="item.popular ? 'white--text' : 'grey--text text--lighten-4'"
                      >
                          {{ $t("mostPopular") }}
                      </h3>
                      <v-card :class="!$vuetify.breakpoint.mobile ? 'card round' : 'round card-mobile'">
                          <v-card-text>
                              <h4 class="text-center font-weight-bold">{{ item.name }}</h4>
                              <h5 v-if="item.name === 'Premium'" class="text-overline text-center">( {{ $t('freeTrial') }} ) </h5>
                              <v-col offset="1" cols="10" v-if="item.popular">
                                  <v-list-item v-for="(item, i) in itemsPremium" :key="i">
                                      <v-icon
                                          small
                                          :color="item.included ? '#7EBE72' : 'red lighten-1'"
                                          v-text="item.icon"
                                      ></v-icon>
                                      <v-list-item-content>
                                          <v-list-item-title class="mt-1 ml-2 space-wrap">
                                              {{ $t(item.text) }}
                                          </v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>
                              </v-col>
                              <v-col offset="1" cols="10" class="align-content-center" v-else>
                                  <v-list-item v-for="(item, i) in items" :key="i">
                                      <v-icon
                                          small
                                          :color="item.included ? '#7EBE72' : 'red lighten-1'"
                                          v-text="item.icon"
                                      ></v-icon>
                                      <v-list-item-content>
                                          <v-list-item-title class="mt-1 ml-2 space-wrap">
                                              {{ $t(item.text) }}
                                          </v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>
                              </v-col>
                              <div class="button text-center w-100">
                                <div class="d-flex align-center w-100 justify-content-center mb-4">
                                    <span class="display-1 font-weight-bold text-black">
                                      <money-format
                                        :value="item.cost"
                                        :locale="$i18n.locale"
                                        :currency-code="currencyCode"
                                        :supplemental-precision="0"
                                      />
                                    </span>
                                    <span class="font-weight-bold text-black">/{{ $t("month") }}</span>
                                </div>
                                <div class="text-center">
                                    <v-btn
                                        class="mb-2"
                                        v-if="$options.SUBSCRIPTIONS_ENABLED"
                                        color="bg-green-prosk white--text"
                                        @click="beginOnProsk(item)"
                                        :disabled="(currentUser && (item.id === 1 && isPremiumUser(currentUser)))"
                                    >
                                        {{ $t("begin") }}
                                    </v-btn>
                                </div>
                              </div>
                          </v-card-text>
                      </v-card>
                  </div>
              </v-col>
              </v-row>
          </div>
          <div class="d-flex justify-content-center my-16" v-show="!$vuetify.breakpoint.mobile">
            <subscription-button v-show="!$vuetify.breakpoint.mobile" :subscribe="beginOnProsk" :message="$t('freeTrial30')" />
          </div>
        </v-container>
    </v-row>
    <v-card
      class="info-card"
    >
      <v-row class="width-90">
        <v-col cols="12" class="text-center mb-4 secondary-prosk">
          <h2 class="secondary-prosk font-weight-bold mt-16 mb-8">{{ $t("faqs") }}</h2>
        </v-col>
        <v-expansion-panels accordion flat class="mb-6 secondary-prosk" focusable v-model="activeTab">
          <v-expansion-panel v-for="(item, i) in faqs" :key="i" class="bg-grey">
            <v-expansion-panel-header
                class="font-weight-bold secondary-prosk"
                :class="{'bg-green-prosk': i === activeTab}"
            >
              <template v-slot:actions>
                <v-icon :color="i === activeTab ? 'white' : '#59D79D'">
                  {{  i === activeTab ? 'mdi-minus' : 'mdi-plus' }}
                </v-icon>
              </template>
              {{ $t(item.question) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mt-5 secondary-prosk">
              {{ $t(item.answer) }}
              <router-link v-show="item.link" :to="{ name: item.link }"> {{ $t("signUp") }}</router-link>
              <span v-show="item.answer2">{{$t(item.answer2)}}</span>
              <span v-show="item.answer3" class="font-weight-bold secondary-prosk"> {{$t(item.answer3)}} </span>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-card>
    <v-container class="mt-10">
      <div class="d-flex">
        <div class="secondary-prosk width-50">
          <h3 class="font-weight-bold green-prosk">{{ $t("digitalOffice") }}</h3>
          <p>{{ $t("saveTime") }}</p>
          <h4 class="font-weight-bold secondary-prosk">{{ $t("bringWithYou") }}</h4>
          <p>{{ $t("manageFromWherever") }}</p>
          <h4 class="font-weight-bold secondary-prosk">{{ $t("acceptPayment") }}</h4>
          <p>{{ $t("acceptPaymentAnswer") }}</p>
          <h4 class="font-weight-bold secondary-prosk">{{ $t("comunication") }}</h4>
          <p>{{ $t("comunicationSubtext") }}</p>
        </div>
        <img
          v-show="!$vuetify.breakpoint.mobile"
          :src="require('@/assets/images/page-img/office.svg')"
          class="width-50"
        />
      </div>
      <div class="d-flex">
        <img
          v-show="!$vuetify.breakpoint.mobile"
          :src="require('@/assets/images/page-img/subscription-image.svg')"
          class="width-50"
        />
        <div class="secondary-prosk width-50">
          <h3 class="font-weight-bold green-prosk">{{ $t("reachMoreBuyers") }}</h3>
          <p>{{ $t("potentiate") }}</p>
          <h4 class="font-weight-bold secondary-prosk">{{ $t("servicesInSamePlace") }}</h4>
          <p>{{ $t("servicesInSamePlaceSubtext") }}</p>
          <h4 class="font-weight-bold secondary-prosk">{{ $t("proProfile") }}</h4>
          <p>{{ $t("customizeProfile") }}</p>
          <h4 class="font-weight-bold secondary-prosk">{{ $t("electronicCharge") }}</h4>
          <p>{{ $t("mercadoPagoCharge") }}</p>
        </div>
      </div>
      <div class="my-12" v-show="!$vuetify.breakpoint.mobile">
        <subscription-button :subscribe="beginOnProsk" :message="$t('freeTrial30')" />
      </div>
    </v-container>
  </v-main>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import MoneyFormat from 'vue-money-format';
import CryptoJS from 'crypto-js';
import { FEATURE_SUBSCRIPTIONS, AES_SECRET } from '@/misc/constants';
import { isFeatureEnabled } from '@/misc/featureFlagService';
import SubscriptionButton from '@/components/socialvue/buttons/SubscriptionButton';

export default {
  name: 'SubscriptionPage',
  components: {
    SubscriptionButton,
    MoneyFormat
},
  data () {
    return {
      skeletonAttrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
      },
      loading: true,
      currencyCode: '',
      itemsCard: [
        {
          step: 1,
          text: 'createYourProskerProfile'
        },
        {
          step: 2,
          text: 'showWhatYouDo'
        },
        {
          step: 3,
          text: 'communicateWithClients'
        }
      ],
      activeTab: null,
      subscriptionInformation: {},
      itemsPremium: [
        {
          text: 'publicProfile',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        {
          text: 'customAccount',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        { text: 'Chat', icon: 'mdi-check-circle-outline', included: true },
        {
          text: 'electronicalCharge',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        {
          text: 'premiumFee',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        {
          text: 'virtualDiary',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        {
          text: 'positioning',
          icon: 'mdi-check-circle-outline',
          included: true
        }
      ],
      items: [
        {
          text: 'publicProfile',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        {
          text: 'internalPublications',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        { text: 'Chat', icon: 'mdi-check-circle-outline', included: true },
        {
          text: 'electronicalCharge',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        {
          text: 'preferencialFee',
          icon: 'mdi-check-circle-outline',
          included: true
        }

      ],
      faqs: [
        {
          question: 'whatIsPROSK',
          answer: 'whatIsPROSKAnswer'
        },
        {
          question: 'howDoIRegisterInPROSK',
          answer: 'createYourUsernamePasswordCompletingForm',
          link: 'auth1.sign-up1',
          answer2: 'thenCompleteYouProfile'
        },
        {
          question: 'isMyPROSKProfileUnique',
          answer: 'yourPROSKProfileIsUnique',
          answer2: 'whereSelectAllCategories'
        },
        {
          question: 'howCanIHaveGoodReputation',
          answer: 'TrustIsEssentialDependsOnQuality'
        },
        {
          question: 'howCanIMakeAttractiveProfile',
          answer: 'takingCareOfImage',
          answer2: 'takingCareOfImage2'
        },
        {
          question: 'howDoUsersContactMe',
          answer: 'usersRegisteredInPROSKWillSendYouMessage'
        },
        {
          question: 'howDoesPROSKNotifyMe',
          answer: 'currentlyFromProfileWillHaveNotifications'
        },
        {
          question: 'canIChargeServices',
          answer: 'soonPROSKWillHaveServiceAcceptPayments'
        },
        {
          question: 'whatDoINeedToCollectServices',
          answer: 'whatDoINeedToCollectServicesAnswer'
        },
        {
          question: 'canISchedule',
          answer: 'soonPROSKWillHaveDigitalAgenda',
          answer2: 'soonPROSKWillHaveDigitalAgenda2'
        },
        {
          question: 'doesPROSKChargeCommission',
          answer: 'noThatDependsHowYouUsePlatform'
        },
        {
          question: 'doIHaveToPaySubscription',
          answer: 'itDependsOnYou',
          answer2: 'itDependsOnYou2',
          answer3: 'itDependsOnYou3'
        },
        { question: 'isThereTrialPeriod', answer: 'yesInAllCasesOffer30Days' },
        {
          question: 'howDoIReceivePayment',
          answer: 'yourMercadoPagoAccount'
        },
        {
          question: 'howLongShouldIWaitCollectSales',
          answer: 'chargeDelay'
        },
        {
          question: 'doIHaveToIssueInvoices',
          answer: 'serviceYouProvideYouAreResponsible'
        },
        {
          question: 'ifIGetJobThroughAmICovered',
          answer: 'youAreResponsibleComplyingObligations'
        },
        {
          question: 'dataProtected',
          answer: 'dataProtectedAnswer',
          answer2: 'dataProtectedAnswer2'
        }
      ],
      subscriptionType: {},
      subscriptionPlans: []
    };
  },
  created () {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
    this.currencyCode = process.env.VUE_APP_LOCATION.includes('br') ? 'BRL' : 'UYU';
    this.setImportedConstants();
    this.getSubscriptionPlans();
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    setImportedConstants () {
      this.$options.SUBSCRIPTIONS_ENABLED = isFeatureEnabled(process.env.VUE_APP_RELEASE_VERSION, FEATURE_SUBSCRIPTIONS);
      this.$options.AES_SECRET = AES_SECRET;
    },
    sendEmailOrLogin () {
      if (!this.currentUser) {
        this.$router.push({ name: 'auth1.sign-in1' });
      } else {
        const url = `${process.env.VUE_APP_BASE_URL}/api/subscriptions/contact`;
        const data = {
          user: this.currentUser
        };
        axios.post(url, data).then(() => {
          this.$swal.fire({
            title: this.$t('awesome'),
            text: this.$t('queryReceived'),
            confirmButtonColor: '#19AD93'
          });
        });
      }
    },
    sendToRegisterFreePlan () {
      this.subscriptionType.free = true;
      this.$router.push({
        name: 'auth1.sign-up1',
        params: { query: JSON.stringify(this.subscriptionType) }
      });
    },
    sendToCheckoutPremiumPlan (subscription) {
      this.subscriptionInformation = subscription;
      this.subscriptionInformation.isSubscriptionType = true;
      this.subscriptionInformation.isUserLogged = true;
      this.$router.push({
        name: 'subscription-checkout',
        params: { subscriptionId: CryptoJS.AES.encrypt(subscription.id.toString(), this.$options.AES_SECRET).toString() }
      });
    },
    sendToCheckoutNotLoggedPremiumPlan (subscription) {
      this.subscriptionInformation = subscription;
      this.subscriptionInformation.isSubscriptionType = true;
      this.subscriptionInformation.isUserLogged = false;
      this.$router.push({
        name: 'subscription-checkout',
        params: { subscriptionId: CryptoJS.AES.encrypt(subscription.id.toString(), this.$options.AES_SECRET).toString() }
      });
    },
    isPremiumUser (user) {
      return user.active_subscription.find(item => item.name === 'Premium');
    },
    beginOnProsk (subscription) {
      if (!this.currentUser) {
        this.sendToRegisterFreePlan();
      } else if (this.currentUser && subscription.id === 2) {
        this.sendToCheckoutPremiumPlan(subscription);
      }
    },
    getSubscriptionPlans () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/subscriptions`;
      this.loading = true;
      axios.get(url).then((response) => {
        this.subscriptionPlans = response.data.data.filter(item => item.name !== 'Free Trial').reverse();
        this.loading = false;
      });
    }
  }
};
</script>
<style scoped>
.button-absolute {
  position: absolute;
  bottom: 60px;
  right: 400px;
}

.button-absolute-mobile {
  position: absolute;
  bottom: 10px;
  right: 0px;
  display: none;
}

.button-absolute-mobile > button {
 height: 30px !important;
}

.info-card {
  border-radius: 2rem;
  margin: 24px 36px;
  width: calc(100%-72px);
  background-color: #f1f1f1;
  color: #3F44A6;
  box-shadow: 12px 10px 5px rgba(0, 0, 0, .1);
}

.width-50 {
  width: 50%;
  margin: 12px 20px;
}

.width-90 {
  width: 90%;
  padding-left: 10%;
}

.sized-div {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
}

.space-wrap {
  white-space: pre-wrap;
}

.custom-radio {
  border-radius: 100%;
  height: 180px;
  width: 160px;
  padding: 24px;
  box-shadow: 4px 2px 2px rgba(0, 0, 0, .1);
  margin-bottom: 12px;
}

.card {
  height: 620px;
  position: relative;
}

.card-mobile {
  height: 620px;
  position: relative;
}

.card .button {
  position: absolute;
  bottom: 32px;
  left: 0;
}

.mobile-subscription {
  position: absolute;
  width: 80%;
  bottom: 0px;
  left: -18%;
}

.line-separator {
  padding: 40px 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

@media (max-width: 1264px) {
  .width-50 {
    width: 100%;
  }
}

</style>
