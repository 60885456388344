<template>
  <v-btn
     @click="subscribe"
     color="#59D79D"
     class="button"
     :height="$vuetify.breakpoint.mobile ? 30 : 50"
     :width="$vuetify.breakpoint.mobile  ? '100%' : ''"
    :style="{ 'font-size': $vuetify.breakpoint.mobile ? '0.8rem' : '1.1rem' }"
    >
    {{message}}
  </v-btn>
</template>

<script>
export default {
  name: 'SubscriptionButton',
  props: ['subscribe', 'message']
};
</script>
<style scoped>
.button {
  padding: 12px !important;
  border-radius: 50px;
  color: #3F44A6;
  font-weight: 700;
}
</style>
